
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop, Watch} from 'vue-property-decorator';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {DateTime} from 'luxon';

    import {
        IAgenda,
        IAnimalType,
        IListItem,
        IOrganization,
        IOrganizationReasonsParams,
        IOrganizationSpeciesParams,
        IReason,
        ISpecies,
        IVeterinarian,
        ISearchOrganizationsBlacklisted,
        IOrganizationsBlacklisted,
        IOrganizationRule,
        IClient,
    } from '@/types';

    import {SearchModule} from '@/store/modules';

    import Openings from '@/components/Openings.vue';

    const animalNamespace = namespace('animal');
    const searchNamespace = namespace('search');
    const accountNamespace = namespace('account');

    @Component({
        components: {
            Openings,
        },
    })
    export default class BookingWidget extends Vue {
        public hasNoOpenings = false;
        public loaded = false;

        public selectedOrganization: IOrganization | null = null;

        public species: ISpecies[] = [];
        public selectedSpecies: ISpecies | null = null;

        public specialties: IListItem[] = [];
        public selectedSpecialty: IListItem | null = null;

        public reasons: IListItem[] = [];
        public selectedReason: IListItem | null = null;

        public agendas: IAgenda[] = [];
        public selectedAgenda: IAgenda | null = null;

        public validatedInstructions: object[] = [];

        public loadingTypes: boolean = false;
        public loadingSpecialties: boolean = false;
        public loadingReasons: boolean = false;
        public loadingAgendas: boolean = false;

        public currentInstruction!: string;

        @Prop({
            type: Object,
            required: false,
        })
        public organization!: IOrganization;

        @Prop({
            type: Object,
            required: false,
        })
        public veterinarian!: IVeterinarian;

        @Prop({
            type: Object,
            required: false,
        })
        public preselectedOrganization!: IOrganization;

        @Prop({
            type: Object,
            required: false,
        })
        public preselectedSpecies!: ISpecies;

        @Prop({
            type: Object,
            required: false,
        })
        public preselectedReason!: IReason;

        @Prop({
            type: Object,
            required: false,
        })
        public preselectedTime!: DateTime;

        @Prop({
            type: Boolean,
            required: false,
            default: false,
        })
        public sticky!: boolean;

        @Prop({
            type: Boolean,
            required: false,
            default: false,
        })
        public closeable!: boolean;

        @animalNamespace.Getter('speciesList')
        public speciesList!: ISpecies[];

        @animalNamespace.Getter('typesList')
        public typesList!: IAnimalType[];

        @searchNamespace.Getter('searchedSpecies')
        public searchedSpecies!: ISpecies | null;

        @accountNamespace.Getter('loggedClient')
        public loggedClient!: IClient;

        @searchNamespace.Getter('getOrganizationBlacklisted')
        public organizationsBlacklisted!: IOrganizationsBlacklisted;

        @Watch('preselectedOrganization')
        public onPreselectedOrganizationChanged() {
            if (this.preselectedOrganization) {
                this.loaded = false;
                this.selectedOrganization = JSON.parse(JSON.stringify(this.preselectedOrganization));
                this.loadSpecies();
            }
        }

        public changeFocus(oldEl: string, newEl: string) {
            this.$nextTick(() => {
                const oldElRef = this.$refs[oldEl];
                const newElRef = this.$refs[newEl];

                if (oldElRef) {
                    (oldElRef as HTMLElement).blur();
                }

                if (newElRef) {
                    (newElRef as HTMLElement).focus();
                }
            });
        }

        public blacklistedList() {
            if (!this.loggedClient) {
                return;
            }
            if (!this.organization.id) return;
            const params: ISearchOrganizationsBlacklisted = {
                client_id: this.loggedClient.id,
                organizations: [this.organization.id],
            };

            getModule(SearchModule, this.$store).setBlacklistedOrganizations(params);
        }

        public ruleIssue(reasonIssue: IOrganizationRule) {
            this.selectedReason = reasonIssue.organization_reason_issue;
            this.loadAgendas();
        }

        public blur(el: string) {
            this.$nextTick(() => {
                (this.$refs[el] as HTMLElement).blur();
            });
        }

        get organizations() {
            if (!this.veterinarian || !this.veterinarian.agendas) {
                return [];
            }

            return this.veterinarian.agendas.map((agenda: IAgenda) => {
                return agenda.organization;
            });
        }

        get veterinarianAgenda() {
            if (!this.veterinarian) {
                return null;
            }

            if (!this.selectedOrganization) {
                return null;
            }

            return this.veterinarian.agendas?.find((agenda: IAgenda) => {
                return agenda.organization?.id === this.selectedOrganization?.id;
            });
        }

        get filteredSpeciesByType() {
            const typesById = this.typesList.reduce((carry: { [key: string]: IAnimalType }, type: IAnimalType) => {
                carry[type.id] = type;
                return carry;
            }, {});

            const speciesByType = this.species.reduce((carry: { [key: string]: IAnimalType }, species: ISpecies) => {
                if (!carry[species.animal_type_id]) {
                    (carry as any)[species.animal_type_id] = {
                        name: typesById[species.animal_type_id].name,
                        species: [],
                    };
                }

                carry[species.animal_type_id].species.push(species);

                return carry;
            }, {});

            const sortedKeys: string[] = Object.keys(speciesByType).sort((a: string, b: string) => {
                const aName = speciesByType[a].name;
                const bName = speciesByType[b].name;

                return aName < bName ? -1 : 1;
            });

            return sortedKeys.reduce((carry: any[], key: string) => {
                const typeSpecies = speciesByType[key];
                const sortedSpecies = typeSpecies.species.sort((a: ISpecies, b: ISpecies) => {
                    return a.name < b.name ? -1 : 1;
                });

                return [...carry, {header: typeSpecies.name}, ...sortedSpecies];
            }, []);
        }

        get reasonClasses() {
            return {
                'active': !!this.selectedReason && !this.selectedAgenda,
                'mb-6': !!this.veterinarian,
                'mb-2': !this.veterinarian,
            };
        }

        private clearSpecies() {
            const element = this.$refs.species;

            this.selectedSpecies = null;

            if (element) {
                (element as HTMLFormElement).internalSearch = null;
            }
        }

        private clearSpecialty() {
            const element = this.$refs.specialty;

            this.selectedSpecialty = null;

            if (element) {
                (element as HTMLFormElement).internalSearch = null;
            }
        }

        private clearReason() {
            const element = this.$refs.reason;

            this.selectedReason = null;

            if (element) {
                (element as HTMLFormElement).internalSearch = null;
            }
        }

        private clearAgenda() {
            const element = this.$refs.agenda;

            this.selectedAgenda = null;

            if (element) {
                (element as HTMLFormElement).internalSearch = null;
            }
        }

        private loadSpecies() {
            if (!this.selectedOrganization) {
                this.loaded = true;
                return;
            }

            this.hasNoOpenings = false;

            this.clearSpecies();
            this.clearSpecialty();
            this.clearReason();
            this.clearAgenda();

            const params: IOrganizationSpeciesParams = {
                organization_id: this.selectedOrganization.id as string,
            };

            if (this.veterinarianAgenda) {
                params.agenda_id = this.veterinarianAgenda.id as string;
            }

            this.loadingTypes = true;

            this.$api.booking
                .getSpecies(params)
                .then((species) => {
                    this.species = species;

                    if (this.species.length === 0) {
                        this.hasNoOpenings = true;
                    } else if (this.preselectedSpecies) {
                        this.selectedSpecies = this.preselectedSpecies;
                        this.loadSpecialties();
                    } else if (this.searchedSpecies) {
                        this.selectedSpecies = this.searchedSpecies;
                        this.loadSpecialties();
                    }

                    this.loaded = true;
                })
                .then(() => this.loadingTypes = false)
                .then(() => this.changeFocus('organization', 'type'))
            ;
        }

        private loadSpecialties() {
            if (!this.selectedSpecies) {
                return;
            }

            this.clearSpecialty();
            this.clearReason();
            this.clearAgenda();

            if (this.veterinarian) {
                this.selectedSpecialty = this.veterinarian.specialty as IListItem;
                this.loadReasons();

                return;
            }

            this.loadingSpecialties = true;

            this.$api.booking
                .getSpecialties({
                    organization_id: (this.selectedOrganization as IOrganization).id as string,
                    species_id: this.selectedSpecies.id as string,
                })
                .then((specialties) => {
                    this.specialties = specialties;

                    if (this.specialties.length === 1)  {
                        this.selectedSpecialty = this.specialties[0];
                        this.loadReasons();
                    }
                })
                .then(() => this.loadingSpecialties = false)
                .then(() => this.changeFocus('type', 'specialty'))
            ;
        }

        private loadReasons() {
            if (!this.selectedSpecialty) {
                return;
            }

            this.clearReason();
            this.clearAgenda();

            const params: IOrganizationReasonsParams = {
                organization_id: (this.selectedOrganization as IOrganization).id as string,
                species_id: (this.selectedSpecies as ISpecies).id as string,
                specialty_id: this.selectedSpecialty.id as string,
            };

            if (this.veterinarianAgenda) {
                params.agenda_id = this.veterinarianAgenda.id as string;
            }

            this.loadingReasons = true;

            this.$api.booking
                .getReasons(params)
                .then((reasons: IListItem[]) => {
                    this.reasons = reasons;

                    if (this.reasons.length === 1)  {
                        this.selectedReason = this.reasons[0];
                        this.loadAgendas();
                    }

                    if (this.preselectedReason) {
                        for (const reason of this.reasons) {
                            if (reason.id === this.preselectedReason.id) {
                                this.selectedReason = reason;
                                this.loadAgendas();
                                break;
                            }
                        }
                    }
                })
                .then(() => this.loadingReasons = false)
                .then(() => this.changeFocus(this.veterinarian ? 'type' : 'specialty', 'reason'))
            ;
        }

        private loadAgendas() {
            if (!this.selectedReason) {
                return;
            }

            this.clearAgenda();

            if (this.veterinarianAgenda) {
                this.selectedAgenda = {
                    id: this.veterinarianAgenda.id,
                    public_name: `${this.veterinarian.first_name} ${this.veterinarian.last_name}`,
                };

                this.blur('reason');

                return;
            }

            this.loadingAgendas = true;

            this.$api.booking
                .getAgendas({
                    organization_id: (this.selectedOrganization as IOrganization).id as string,
                    species_id: (this.selectedSpecies as ISpecies).id as string,
                    specialty_id: (this.selectedSpecialty as IListItem).id as string,
                    reason_id: this.selectedReason.id as string,
                })
                .then((agendas) => {
                    this.agendas = agendas.filter((agenda: IAgenda) => agenda.is_directly_bookable);

                    if (this.agendas.length > 1 || this.agendas.length !== agendas.length) {
                      this.agendas.unshift({id: null, public_name: 'Premier vétérinaire disponible'});
                    }

                    this.selectedAgenda = this.agendas[0];
                    this.blacklistedList();
                })
                .then(() => this.loadingAgendas = false)
                .then(() => this.changeFocus('reason', 'agenda'))
            ;
        }

        private data() {
            return {
                selectedOrganization: this.organization ?
                    this.organization :
                    (
                        this.veterinarian && this.veterinarian.agendas?.length === 1 ?
                            this.veterinarian.agendas[0].organization :
                            (
                                this.preselectedOrganization ?
                                    this.preselectedOrganization :
                                    null
                            )
                    ),
            };
        }

        private created() {
            this.loadSpecies();
        }
    }
